import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-checkbox",
  templateUrl: "./checkbox.component.html",
  styleUrls: ["../input-styles.scss", "./checkbox.component.scss"],
})
export class CheckboxComponent implements OnInit {
  @Input() name: string;
  @Input() description: string;
  @Input() tooltip: string;
  @Input() required: boolean;
  @Input() option: string;
  @Input() icon: string;
  @Input() largerSize = false;
  @Input() validationDisabled = false;
  @Input() submitted = false;
  @Input("ngModel") checked: boolean;
  @Output("ngModelChange") checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  inputId: string;

  constructor() {}

  ngOnInit(): void {
    this.inputId = `${this.name}_${Math.random().toString(36).substring(2)}`;
  }

  isOptionSelected(): boolean {
    return this.checked;
  }

  changeOptionState() {
    this.checked = !this.checked;
    if (!this.checked) {
      this.checked = null;
    }
    this.checkedChange.emit(this.checked);
  }
}
