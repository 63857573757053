import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { DomModal } from "models/dom_modal";
import { ApiService } from "api_service";
import { Subscription } from "rxjs";
import { PushNotificationService } from "services/push_notification.service";
import { StorageService } from "services/storage.service";
import { ModalService } from "common/modal/modal.service";
import { Globals } from "base";
import { GenericData } from "models/pms/generic_data";
import { NgForm } from "@angular/forms";

@Component({
  selector: "app-push-categories",
  templateUrl: "./categories.component.html",
  styleUrls: ["categories.component.scss"],
})
export class PushCategoriesComponent implements OnInit, OnDestroy {
  @Input() embeddedInModal = true;

  subscriptions: Subscription = new Subscription();
  categories: any[];
  activated: Number[];
  countries: Array<any>;
  isWhatsAppChecked: boolean = false;
  travelJourneysChecked: boolean = false;
  isServicesChecked: boolean = false;
  phone: string;
  submitted: boolean = false;
  whatsAppSectionVisible: boolean;

  constructor(
    public globals: Globals,
    private api: ApiService,
    private pushNotificationService: PushNotificationService,
    private modalService: ModalService,
    private storageService: StorageService,
  ) {}

  ngOnInit() {
    this.fetchCountries();
    this.initializeGlobalSettings();
    this.fetchCategories();
  }

  private initializeGlobalSettings() {
    this.globals.setModule("push");
    this.globals.init_send_cancel_btn();
  }

  private fetchCountries() {
    this.subscriptions.add(
      this.api.get("countries", true).subscribe(
        (countryData: any) => {
          this.countries = GenericData.mapCountries(countryData.countries);
          this.fetchWhatsAppSettings();
        },
        (error) => {
          console.error("Error fetching countries:", error);
        },
      ),
    );
  }

  private fetchWhatsAppSettings() {
    if (this.globals.guest.place.cico_login) return;
    this.subscriptions.add(
      this.pushNotificationService.getWhatsApp().subscribe(
        (value: any) => {
          if (value) {
            this.phone = value.pms_guest.phone;
            this.travelJourneysChecked = value.pms_guest.whatsapp?.wa_journey;
            this.isServicesChecked = value.pms_guest.whatsapp?.wa_service;
            this.isWhatsAppChecked = this.travelJourneysChecked || this.isServicesChecked;
            this.whatsAppSectionVisible = !this.embeddedInModal ? true : this.isWhatsAppChecked;
          }
        },
        (error) => {
          console.error("Error fetching WhatsApp settings:", error);
        },
      ),
    );
  }

  private fetchCategories() {
    this.subscriptions.add(
      this.pushNotificationService.getCategories().subscribe(
        (success: any) => {
          this.categories = success.push_categories;
          this.activated = !this.globals.guest.subscription_id || this.modalService.isShowing ? this.categories.map((item) => item["id"]) : this.globals.guest.push_categories;
        },
        (error) => {
          console.error("Error fetching categories:", error);
        },
      ),
    );
  }

  check(id) {
    if (!this.activated.includes(id)) {
      this.activated.push(id);
    } else {
      const index = this.activated.indexOf(id, 0);
      if (index > -1) {
        this.activated.splice(index, 1);
      }
    }
  }

  onSubmit(form: NgForm) {
    this.handleWhatsAppSubmission(form);
    this.handlePushNotification();
  }

  private handleWhatsAppSubmission(form: NgForm) {
    if (this.globals.guest.place.cico_login) return true;

    this.submitted = !form.valid;
    if (this.submitted) return;

    this.saveWhatsAppInfo();
  }

  private handlePushNotification() {
    if (!!this.globals.guest) {
      this.globals.guest.push_categories = this.activated;
    }
    if (!this.globals.guest.hasValidSubscription() || this.modalService.isShowing) {
      if (this.modalService.isShowing) {
        this.modalService.close();
      }
    }
    this.pushNotificationService
      .requestPermission()
      .then((token) => {
        this.updateCategories(token);
      })
      .catch();
  }

  private saveWhatsAppInfo() {
    const data = {
      notification_preferences: {
        whatsapp: {
          wa_journey: this.travelJourneysChecked,
          wa_service: this.isServicesChecked,
        },
        phone: this.phone,
      },
    };
    this.pushNotificationService.updateWhatsApp(data).subscribe(
      (res) => {
        this.submitted = false;
        this.success();
      },
      (err: any) => {
        this.globals.alert("error", this.globals.translate("validation.error"));
      },
    );
  }

  updateCategories(token) {
    const push_categories = this.globals.guest.push_categories;
    this.pushNotificationService
      .updateCategories({ push_categories: push_categories, token: token })
      .then(() => {
        if (push_categories && push_categories.length === 0) {
          this.globals.guest.subscription_id = -1;
        }
        this.storageService.setItem("current_guest", this.globals.guest.toLocalStorage());
        this.success();
      })
      .catch(() => {
        this.globals.alert("error", this.globals.translate("validation.error"));
      });
  }

  success() {
    const body = new DomModal();
    body.title = "misc.thank_you";
    body.description = "push.edit_subsciption";
    this.modalService.open(body, true);
  }

  onWhatsAppChecked() {
    const isChecked = this.isWhatsAppChecked;
    this.travelJourneysChecked = isChecked;
    this.isServicesChecked = isChecked;

    if (!isChecked) {
      this.phone = undefined;
    }
    // Determine the visibility of the WhatsApp section
    this.whatsAppSectionVisible = !this.embeddedInModal || isChecked;
  }

  onSubCheckboxChange() {
    this.isWhatsAppChecked = this.travelJourneysChecked || this.isServicesChecked;
    if (this.phone) {
      this.phone = "";
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
