import { Injectable } from "@angular/core";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { BehaviorSubject, Observable } from "rxjs";
import { filter, mergeMapTo, take } from "rxjs/operators";
import { ApiService } from "api_service";
import { GuestService } from "./guest.service";
import { Guest } from "models/guest";

@Injectable({ providedIn: "root" })
export class PushNotificationService {
  private notificationSubj: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  notificationState: Observable<string> = this.notificationSubj.asObservable();

  constructor(
    private api: ApiService,
    private guestService: GuestService,
    private afMessaging: AngularFireMessaging,
  ) {
    if ("permissions" in navigator) {
      const self = this;
      (<any>navigator).permissions
        .query(Object.assign({ name: "notifications" }))
        .then((permission) => {
          if (permission && permission.state) {
            self.notificationSubj.next(permission.state);
          }
          permission.addEventListener("change", function () {
            self.notificationSubj.next(permission.state);
          });
        })
        .catch(() => {});
    }
  }

  public requestPermission(): any {
    return new Promise((resolve, reject) => {
      const self = this;
      this.afMessaging.requestPermission.pipe(mergeMapTo(this.afMessaging.tokenChanges)).subscribe(
        (token) => {
          self.push_subscription(token).then(() => {
            resolve(token);
          });
        },
        (e: any) => {
          console.log(e);
          reject();
        },
      );
    });
  }

  public updateCategories(data: any): any {
    return new Promise<void>((resolve, reject) => {
      this.api.post("pwa/push_subscription", data).subscribe(
        (success: any) => {
          if (success?.subscription) {
            this.guestService.guest.subscription_id = success.subscription.id;
            resolve();
          }
        },
        (error) => {
          reject();
        },
      );
    });
  }

  public updateWhatsApp(data: any) {
    return this.api.post("whatsapp_subscription", data);
  }

  public getCategories(): Observable<Object> {
    return this.api.get("pwa/push_categories");
  }

  public getWhatsApp(): Observable<Object> {
    return this.api.get("whatsapp_subscription");
  }

  public getSubscription(token: string): Observable<Object> {
    return this.api.get("pwa/push_subscription/" + token);
  }

  private push_subscription(token): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.guestService.currentGuest.pipe(filter(Boolean), take(1)).subscribe(
        (guest: Guest) => {
          const data = { token: token, push_categories: guest.push_categories };
          this.updateCategories(data);
          resolve();
        },
        (error) => {
          console.log(error);
          reject();
        },
      );
    });
  }
}
