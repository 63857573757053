import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SendCancelModule } from 'shared/forms/send_cancel/send_cancel.module';
import { TypographyModule } from 'common/typography/typography.module';
import { PushCategoriesComponent } from './categories.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    SendCancelModule,
    TypographyModule
  ],
  exports: [
    PushCategoriesComponent
  ],
  declarations: [PushCategoriesComponent]
})
export class PushCategoriesModule {}
