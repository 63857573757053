<ng-container *ngIf="data">
  <ng-container *ngIf="!pending">

    <div class="animation-out" *ngIf="!authUrl?.length && !authFailed">
      <!-- uncomment if country box should be used instead of normal select -->
      <!-- <app-country-box-group *ngIf="nationalityField" [options]="data.countriesNat" [model]="guest" [name]="'nationality'" [authRequired]="authRequired" [label]="nationalityField.name" [errorLabel]="'validation.nationality'" [text]="nationalityField.description" [required]="nationalityField.required" (optionValue)="checkAuthMethods()"></app-country-box-group> -->
      <app-form-select *ngIf="nationalityField" [model]="guest" [key]="'nationality'" name="nationality{{ index }}" [label]="nationalityField.name" [errorLabel]="'validation.nationality'" [options]="data.countriesNat" [showIcons]="true" [tooltip]="nationalityField.descriptionn" [required]="nationalityField.required" [redesign]="true" (optionValue)="checkAuthMethods(isIdnowActive)"></app-form-select>

      <div class="animation-in" *ngIf="verificationOptions?.length && guest.nationality">
        <app-description-label class="description-label" [description]="'service.check_in.authentication.description' | translate" [tooltip]="'service.check_in.authentication.tooltip' | translate"></app-description-label>
        <div>
          <app-verification-box-group [options]="verificationOptions" [(ngModel)]="selVerification" (click)="IdfMethod()"></app-verification-box-group>
        </div>
      </div>
    </div>

    <div class="iframe_container" *ngIf="authUrl?.length && !authFailed">
      <iframe [src]="authUrl | safe" id="external_link" allow="camera *; microphone *" allowfullscreen=""></iframe>
    </div>

    <div *ngIf="authFailed" class="animation-in">
      <div class="desc">
        <div class="instructions not-first-instruction" [innerHTML]="ui_messages.auth_failed?.content"></div>
      </div>
      <div class="button-row">
        <button type="button" class="btn-filled" (click)="retryAuth()" translate>service.folios.retry</button>
        <button type="button" class="btn-outline" (click)="manual()" *ngIf="authRequired === 'optional'" translate>service.check_in.authentication.manual</button>
      </div>
    </div>
  </ng-container>

  <app-loader [text]="'service.check_in.authentication.pending'" *ngIf="pending"></app-loader>
</ng-container>

