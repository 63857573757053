<form #form="ngForm" (ngSubmit)="onSubmit(form)">
  <div class="group" *ngIf="!globals.guest.place.cico_login && globals.guest.can_subscribe_whatsapp">
    <app-checkbox ngDefaultControl [name]="'whatsapp_general'" [option]="'push.whats_app' | translate" icon="fa-brands fa-whatsapp" [largerSize]="true" [validationDisabled]="true" [(ngModel)]="isWhatsAppChecked" (ngModelChange)="onWhatsAppChecked()"></app-checkbox>
    <ng-container *ngIf="whatsAppSectionVisible">
      <div class="checkbox-row space-left">
        <app-checkbox ngDefaultControl [name]="'extra_travel_journeys'" [option]="'push.travel_journeys' | translate" [(ngModel)]="travelJourneysChecked" [validationDisabled]="true" (ngModelChange)="onSubCheckboxChange()"></app-checkbox>
        <app-checkbox ngDefaultControl [name]="'extra_services'" [option]="'push.services' | translate" [(ngModel)]="isServicesChecked" [validationDisabled]="true" (ngModelChange)="onSubCheckboxChange()"></app-checkbox>
      </div>
      <app-phone-input ngDefaultControl [(phoneNumber)]="phone" [required]="true" class="space-left" name="phone" [placeholder]="'misc.phone_number' | translate" [countries]="countries" [submitted]="submitted"></app-phone-input>
    </ng-container>
  </div>

  <div class="group" *ngIf="globals.guest.can_subscribe_push">
    <app-checkbox ngDefaultControl [name]="'push_notifications'" [option]="'push.title' | translate" icon="fa-solid fa-comment-dots" [largerSize]="true" [validationDisabled]="true"></app-checkbox>
    <div class="checkbox-row space-left" *ngIf="categories">
      <app-checkbox ngDefaultControl *ngFor="let item of categories" ngDefaultControl [name]="'extrax_' + item.id" [description]="'description'" [option]="item.name" [validationDisabled]="true" [ngModel]="activated.includes(item.id)" (ngModelChange)="check(item.id)"></app-checkbox>
    </div>
  </div>

  <p [innerHTML]="'push.info' | translate: { privacy_url: '/g/' + globals.getCode() + '/legal/privacy_policy', data_protection_url: '/g/' + globals.getCode() + '/legal/terms' }" translate></p>
  <button id="send_form" class="btn-filled" [class.center]="embeddedInModal" type="submit" [disabled]="globals.send_cancel_disabled" translate>misc.confirm</button>
</form>
